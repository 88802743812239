import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { version } from '../../../package.json';

const Footer = () => (
  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto" />
      <Col sm="auto">
        <Link to={`/changelog`} className="mb-0 text-600">
          v{version}
        </Link>
      </Col>
    </Row>
  </footer>
);

export default Footer;
