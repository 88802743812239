import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Login from './Login';
import Logout from './Logout';
import { setRedirectPage } from '../../../redux/actions';
import { connect } from 'react-redux';

const AuthCardRoutes = ({ setRedirectPage, match: { url }, props }) => {
  useEffect(() => {
    setRedirectPage(props?.location?.state?.from?.pathname);
  }, [props.location.state.from, setRedirectPage]);
  return (
    <Switch>
      <Route path={`${url}/login`} exact component={Login} />
      <Route path={`${url}/logout`} exact component={Logout} />

      {/*Redirect*/}
      <Redirect to="/errors/404" />
    </Switch>
  );
};

AuthCardRoutes.propTypes = { match: PropTypes.object.isRequired };

const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectPage: (redirectPath) => dispatch(setRedirectPage({ redirectPath }))
  };
};

export default connect(null, mapDispatchToProps)(withRouter(AuthCardRoutes));
