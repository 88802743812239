import React from 'react';
import { connect } from 'react-redux';
import { signOut } from '../redux/actions';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

const Main = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const TempNoGroupInfo = ({ signOut }) => {
  const removeCookie = useCookies(['AUTH_TOKEN'])[2];

  const handleLogut = () => {
    signOut();
    removeCookie('AUTH_TOKEN', { path: '/' });
    removeCookie('USER_ID', { path: '/' });
  };

  return (
    <Main>
      <Container>
        <Row className="justify-content-center">
          <Col lg={7} md={10} sm={12}>
            <Card>
              <CardBody className="m-4">
                <p className="h5 mb-4 text-800">Brak widoku dla danej grupy użytkownika.</p>
                <hr />
                <button className="btn btn-primary btn-sm mt-3" onClick={() => handleLogut()}>
                  Wyloguj
                </button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(TempNoGroupInfo);
