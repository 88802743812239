export const signIn = (user) => {
  return {
    type: 'SIGN_IN',
    payload: user
  };
};

export const signOut = () => {
  return {
    type: 'SIGN_OUT'
  };
};
export const setReloadTrue = () => {
  return {
    type: 'SET_RELOAD_TRUE'
  };
};
export const setReloadFalse = () => {
  return {
    type: 'SET_RELOAD_FALSE'
  };
};
export const setRedirectPage = (page) => {
  return {
    type: 'SET_REDIRECT_PAGE',
    payload: page
  };
};
export const removeRedirectPage = () => {
  return {
    type: 'REMOVE_REDIRECT_PAGE'
  };
};
export const setGlobalLoading = (loader) => {
  return {
    type: 'SET_GLOBAL_LOADING',
    payload: loader
  };
};
