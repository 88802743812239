import React from 'react';
import styled from 'styled-components';
import SpinnerRoller from './SpinnerRoller';

const StyledGlobalLoaderWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  right: 1rem;
  bottom: 1rem;
`;

const GlobalLoader = ({ loading = false }) => {
  return (
    <StyledGlobalLoaderWrapper>{loading && <SpinnerRoller size={5} />}</StyledGlobalLoaderWrapper>
  );
};

export default GlobalLoader;
