import React, { useContext, useEffect } from 'react';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import { connect } from 'react-redux';
import { Redirect, Switch, Route, useLocation, useHistory } from 'react-router-dom';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';
import loadable from '@loadable/component';
import { removeRedirectPage } from '../redux/actions';

const MainDashboard = loadable(() => import('../views/tmbViews/MainDashboard'));
const Clients = loadable(() => import('../views/tmbViews/Clients/Clients'));
const Client = loadable(() => import('../views/tmbViews/Clients/Client'));
const Department = loadable(() => import('../views/tmbViews/Clients/Department'));
const ClientPerson = loadable(() => import('../views/tmbViews/Clients/ClientPerson'));

// const ClientsDevice = loadable(() => import('../views/tmbViews/Clients/Device'));

const Services = loadable(() => import('../views/tmbViews/Services/Services'));
const Service = loadable(() => import('../views/tmbViews/Services/Service'));

const Offers = loadable(() => import('../views/tmbViews/Offers/Offers'));
const Offer = loadable(() => import('../views/tmbViews/Offers/Offer'));

const Users = loadable(() => import('../views/tmbViews/Users/Users'));
const UserClientPerson = loadable(() => import('../views/tmbViews/Users/ClientPerson'));
const UserTmbPerson = loadable(() => import('../views/tmbViews/Users/TmbPerson'));

const Devices = loadable(() => import('../views/tmbViews/Devices/Devices'));
const Device = loadable(() => import('../views/tmbViews/Devices/Device'));

const Agreements = loadable(() => import('../views/tmbViews/Agreements/Agreements'));
const AgreementsNew = loadable(() => import('../views/tmbViews/Agreements/AgreementsNew'));
const Agreement = loadable(() => import('../views/tmbViews/Agreements/Agreement'));

const Crms = loadable(() => import('../views/tmbViews/Crm/Crms'));
const Crm = loadable(() => import('../views/tmbViews/Crm/Crm'));

const Products = loadable(() => import('../views/tmbViews/Products/Products'));
const Product = loadable(() => import('../views/tmbViews/Products/Product'));

const Settlements = loadable(() => import('../views/tmbViews/Settlements/Settlements'));
const Settlement = loadable(() => import('../views/tmbViews/Settlements/Settlement'));

const Warehouses = loadable(() => import('../views/tmbViews/Warehouse/Warehouses'));
const ServiceTopics = loadable(() => import('../views/tmbViews/ServiceTopics/ServiceTopics'));
const ImportCsv = loadable(() => import('../views/tmbViews/ImportCsv'));
const OfferActivities = loadable(() => import('../views/tmbViews/OfferActivities/OfferActivities'));
const Regions = loadable(() => import('../components/common/Regions'));
const ChangeLog = loadable(() => import('../views/tmbViews/ChangeLog/ChangeLog'));

const UserDetails = loadable(() => import('../components/detailsCards/UserDetails'));

const ServicePlanner = loadable(() =>
  import('../views/tmbViews/ServicePlanner/ServicePlannerView')
);

const TmbDashboardLayout = ({ page, removeRedirectPage }) => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (page?.redirectPath) {
      history.push(page.redirectPath);
      removeRedirectPage();
    }
  }, [history, page, removeRedirectPage]);

  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  return (
    <div
      className={
        location.pathname === '/planner' || isFluid || isKanban ? 'container-fluid' : 'container'
      }
    >
      {isVertical && <NavbarVertical isKanban={true} navbarStyle={navbarStyle} />}
      <div className="content">
        <NavbarTop />
        <Switch>
          {/* CLIENTS */}
          <Route path="/" exact component={MainDashboard} />
          <Route path="/clients" exact component={Clients} />
          <Route path="/clients/:clientId" exact component={Client} />
          <Route path="/clients/:clientId/details" exact component={Client} />
          <Route path="/clients/:clientId/departments" exact component={Client} />
          <Route path="/clients/:clientId/persons" exact component={Client} />
          <Route path="/clients/:clientId/devices" exact component={Client} />
          <Route path="/clients/:clientId/agreements" exact component={Client} />
          <Route path="/clients/:clientId/services" exact component={Client} />
          <Route path="/clients/:clientId/offers" exact component={Client} />
          <Route path="/clients/:clientId/crms" exact component={Client} />
          <Route path="/clients/:clientId/departments/:departmentId" exact component={Department} />
          <Route path="/clients/:clientId/department/:departmentId" exact component={Department} />
          <Route
            path="/clients/:clientId/department/:departmentId/details"
            exact
            component={Department}
          />
          <Route
            path="/clients/:clientId/department/:departmentId/persons"
            exact
            component={Department}
          />
          <Route
            path="/clients/:clientId/department/:departmentId/devices"
            exact
            component={Department}
          />
          <Route
            path="/clients/:clientId/departments/:departmentId/devices"
            exact
            component={Department}
          />
          <Route
            path="/clients/:clientId/client-person/:clientPersonId"
            exact
            component={ClientPerson}
          />
          {/* <Route path="/clients/:clientId/device/:deviceId" exact component={ClientsDevice} /> */}

          {/* DEVICES */}
          <Route path="/devices" exact component={Devices} />
          <Route path="/devices/:deviceId" exact component={Device} />
          <Route path="/devices/:deviceId/details" exact component={Device} />
          <Route path="/devices/:deviceId/agreement" exact component={Device} />
          <Route path="/devices/:deviceId/persons" exact component={Device} />
          <Route path="/devices/:deviceId/service" exact component={Device} />

          {/* AGREEMENTS */}
          <Route path="/agreements" exact component={Agreements} />
          <Route path="/agreements-settle" exact component={AgreementsNew} />
          <Route path="/agreements/:agreementId" exact component={Agreement} />

          {/* SERVICES */}
          <Route path="/services" exact component={Services} />
          <Route path="/services/:serviceId" exact component={Service} />
          <Route path="/services/:serviceId/details" exact component={Service} />
          <Route path="/services/:serviceId/settlements" exact component={Service} />
          <Route path="/services/:serviceId/visits" exact component={Service} />

          {/* OFFERS */}
          <Route path="/offers" exact component={Offers} />
          <Route path="/offers/service" exact component={Offers} />
          <Route path="/offers/commercial" exact component={Offers} />
          <Route path="/offers/:offerId" exact component={Offer} />

          {/* USERS */}
          <Route path="/users/client-persons/:clientPersonId" component={UserClientPerson} />
          <Route path="/users/tmb-persons/:tmbPersonId" component={UserTmbPerson} />
          <Route path="/users" component={Users} />

          {/* CRM */}
          <Route path="/crm" exact component={Crms} />
          <Route path="/crm/:crmId" exact component={Crm} />

          {/* PRODUCTS */}
          <Route path="/products" exact component={Products} />
          <Route path="/products/:productId" component={Product} />

          {/* SETTLEMENTS */}
          <Route path="/settlements" exact component={Settlements} />
          <Route path="/settlements/:settlementId" component={Settlement} />

          {/* WAREHOUSE */}
          <Route path="/warehouse" exact component={Warehouses} />
          {/* SERVICE TOPICS */}
          <Route path="/ticket-topics" exact component={ServiceTopics} />
          {/* IMPORT CSV */}
          <Route path="/csv" exact component={ImportCsv} />
          {/* OFFER TOPICS */}
          <Route path="/offer-activities" exact component={OfferActivities} />
          {/* REGIONS */}
          <Route path="/regions" exact component={Regions} />
          {/* CHANGELOG */}
          <Route path="/changelog" exact component={ChangeLog} />
          {/* PROFILE */}
          <Route path="/profile" exact component={UserDetails} />
          {/* SERVICE PLANNER */}
          <Route path="/planner" exact component={ServicePlanner} />
          <Redirect to="/errors/404" />
        </Switch>

        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    page: state.page
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeRedirectPage: () => dispatch(removeRedirectPage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TmbDashboardLayout);
