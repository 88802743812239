import React, { useState, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { connect } from 'react-redux';
import { signOut } from '../../redux/actions';
import defaultProfile from '../../assets/img/default_user.png';
import Avatar from '../common/Avatar';
import { useCookies } from 'react-cookie';
import { publicUploads } from '../../helpers/endpoints';
import { useHistory } from 'react-router-dom';
import { reduceId } from '../../helpers/utils';
import { useQuery } from '@apollo/client';
import { LOAD_CLIENT_PERSON, LOAD_ORGANIZATION } from '../../GraphQL/Queries';

const ProfileDropdown = ({ user, signOut }) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [department, setDepartment] = useState(null);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const removeCookie = useCookies(['AUTH_TOKEN'])[2];
  const removeRfToken = useCookies(['REFRESH_TOKEN'])[2];
  const removeUser = useCookies(['USER_ID'])[2];

  const LoadClient = useQuery(LOAD_CLIENT_PERSON, {
    variables: { id: `${user.clientPerson}` }
  });
  const loadOrganization = useQuery(LOAD_ORGANIZATION, {
    variables: { id: `${LoadClient?.data?.clientPerson?.Organization?.id}` }
  });

  useEffect(() => {
    if (LoadClient.data?.clientPerson) {
      if (loadOrganization.data?.organization) {
        setOrganization(loadOrganization.data.organization.name);
      }

      setDepartment(LoadClient.data.clientPerson.Department.name);
    }
    if (!LoadClient.data?.clientPerson) {
      setOrganization('TMB');
    }
  }, [LoadClient.data, loadOrganization.data]);

  const handleLogut = () => {
    signOut();
    removeCookie('AUTH_TOKEN', { path: '/' });
    removeRfToken('REFRESH_TOKEN', { path: '/' });
    removeUser('USER_ID', { path: '/' });
  };
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onClick={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(!dropdownOpen);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar
          src={user.profileImage ? `${publicUploads}/${user.profileImage}` : defaultProfile}
        />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem disabled className="text-center">
            {user.fullName}
          </DropdownItem>
          <DropdownItem disabled className="text-center">
            {user.email}
          </DropdownItem>
          <DropdownItem disabled className="text-center">
            {/* {organization} */}
            {!loadOrganization.loading && !LoadClient.loading && organization}
          </DropdownItem>
          <DropdownItem disabled className="text-center">
            {/* {department} */}
            {!loadOrganization.loading && !LoadClient.loading && department}
          </DropdownItem>

          <hr className="m-2 p-0" />
          <DropdownItem
            onClick={() =>
              history.push(
                user.tmbPerson ? `/users/tmb-persons/${reduceId(user.tmbPerson)}` : '/profile'
              )
            }
          >
            Profil
          </DropdownItem>
          <DropdownItem onClick={() => handleLogut()}>Wyloguj</DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDropdown);
