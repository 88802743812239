import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../redux/actions';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import axios from 'axios';
import { JWT_LOGIN_URL } from '../../helpers/endpoints';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { LOGGED_USER } from '../../GraphQL/Queries';

const LoginForm = ({ hasLabel, signIn }) => {
  const setCookie = useCookies(['AUTH_TOKEN'])[1];
  const setUserCookie = useCookies(['USER_ID'])[1];
  const setTokenRfCookie = useCookies(['REFRESH_TOKEN'])[1];

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState('');
  const [rfToken, setRfToken] = useState('');

  const LoadedUser = useQuery(LOGGED_USER, {
    variables: {
      id: userId,
      skip: !userId
    }
  });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const loginData = {
      email,
      password
    };
    axios
      .post(JWT_LOGIN_URL, loginData)
      .then(({ data: { data, token, refresh_token } }) => {
        setToken(token);
        setRfToken(refresh_token);
        setUserId(`/api/users/${data.id}`);
        setCookie('AUTH_TOKEN', token, { path: '/' });
      })
      .catch(({ response }) => {
        setIsDisabled(false);
        toast.error(response ? response.data.message : 'Wystąpił nieznany błąd');
        setEmail('');
        setPassword('');
      });
  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  useEffect(() => {
    if (userId && LoadedUser.data && !LoadedUser.loading) {
      signIn({
        userId: LoadedUser.data.user.id,
        email: LoadedUser.data.user.email,
        fullName: `${LoadedUser.data.user.name} ${LoadedUser.data.user.surname}`,
        roles: LoadedUser.data.user.roles,
        clientPerson: LoadedUser.data.user.clientPerson
          ? LoadedUser.data.user.clientPerson.id
          : null,
        tmbPerson: LoadedUser.data.user.tmbPerson ? LoadedUser.data.user.tmbPerson.id : null,
        profileImage: LoadedUser.data.user.avatar || null
      });
      setTokenRfCookie('REFRESH_TOKEN', rfToken, { path: '/' });
      setUserCookie('USER_ID', userId, { path: '/' });
    }
  }, [
    LoadedUser.data,
    LoadedUser.loading,
    signIn,
    rfToken,
    token,
    setCookie,
    setTokenRfCookie,
    setUserCookie,
    userId
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Adres email</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Hasło</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Zapamiętaj mnie"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
      </Row>
      <FormGroup>
        <Button color="danger" block className="mt-3" disabled={isDisabled}>
          Zaloguj
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => dispatch(signIn(user))
  };
};

export default connect(
    null,
    mapDispatchToProps
)(LoginForm);
