import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import ProtectedRoute from '../hoc/ProtectedRoute';
import TmbDashboardLayout from './TmbDashboardLayout';
import ClientDashboardLayout from './ClientDashboardLayout';
import TempNoGroupInfo from './TempNoGroupInfo';
import AuthCardRoutes from '../components/auth/card/AuthCardRoutes';
import ErrorLayout from './ErrorLayout';
import { getCookieValue } from '../helpers/utils';
import styled from 'styled-components';
import Notifications from '../components/common/Notifications';
import GlobalLoader from '../components/common/GlobalLoader';

const StyledLayout = styled.div`
  position: relative;
`;

const Layout = ({ user, globalLoading }) => {
  const [dashboard, setDashboard] = useState(TempNoGroupInfo);
  const [isGlobalLoading, setGlobalLoading] = useState(false);
  useEffect(() => {
    if (Object.values(globalLoading).includes(true)) {
      setGlobalLoading(true);
    } else {
      setGlobalLoading(false);
    }
  }, [globalLoading]);

  //===============================
  // Funkcja zwraca komponent to wyrenederowania na podstawie roli użytkownika
  //===============================
  useEffect(() => {
    const checkUserRole = (roles) => {
      let result = TempNoGroupInfo;
      // TODO: Switch Case
      if (user.isAuth && roles.includes('ROLE_TMB')) result = TmbDashboardLayout;
      else if (user.isAuth && roles.includes('ROLE_CLIENT')) result = ClientDashboardLayout;
      return result;
    };
    let componentToRender = checkUserRole(user.roles);
    setDashboard(componentToRender);
  }, [user.roles, user.isAuth]);
  //===============================

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <ProtectedRoute
          canView={getCookieValue('USER_ID') === null}
          redirectPath="/"
          path="/auth"
          component={AuthCardRoutes}
        />
        <ProtectedRoute
          canView={getCookieValue('USER_ID') !== null}
          redirectPath="/auth/login"
          component={dashboard}
        />
      </Switch>
      <StyledLayout>
        <Notifications />
        <ToastContainer
          transition={Fade}
          closeButton={<CloseButton />}
          position={toast.POSITION.TOP_CENTER}
        />
        <GlobalLoader loading={isGlobalLoading} />
      </StyledLayout>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    globalLoading: state.globalLoading
  };
};

export default connect(mapStateToProps)(Layout);
