export const tmbRoutes = [
  {
    name: 'Klienci',
    to: '/clients',
    exact: true,
    icon: 'building'
  },
  {
    name: 'Urządzenia',
    to: '/devices',
    exact: true,
    icon: 'print'
  },
  {
    name: 'Abonamenty',
    to: '/agreements',
    exact: true,
    icon: 'handshake'
  },
  {
    name: 'Serwis',
    to: '/services',
    exact: true,
    icon: 'tools'
  },
  {
    name: 'Planer serwisu',
    to: '/planner',
    exact: true,
    icon: 'tools'
  },
  {
    name: 'Oferty',
    to: '/offers',
    exact: true,
    icon: 'copy'
  },
  {
    name: 'CRM',
    to: '/crm',
    exact: true,
    icon: 'funnel-dollar'
  },
  {
    name: 'Produkty',
    to: '/products',
    exact: true,
    icon: 'boxes'
  },
  {
    name: 'Rozliczenia',
    to: '/settlements',
    exact: true,
    icon: 'file-signature'
  },
  {
    name: 'Administracja',
    icon: 'lock-open',
    children: [
      { to: '/users/client-persons', name: 'Użytkownicy' },
      { to: '/ticket-topics', name: 'Tematy serwisowe' },
      { to: '/offer-activities', name: 'Czynności serwisowe' },
      { to: '/regions', name: 'Regiony' },
      { to: '/warehouse', name: 'Magazyny' },
      { to: '/csv', name: 'Import CSV' },
      { to: '/agreements-settle', name: 'Rozliczanie Abonamentów' }
    ]
  }
];

export const clientRoutes = [
  {
    name: 'Urządzenia',
    to: '/',
    exact: true,
    icon: 'print'
  },
  {
    name: 'Lokalizacje',
    to: '/departments',
    exact: true,
    icon: 'building'
  },
  {
    name: 'Osoby',
    to: '#',
    exact: true,
    icon: 'id-card'
  },
  {
    name: 'Abonamenty',
    to: '/agreements',
    exact: true,
    icon: 'handshake'
  },
  {
    name: 'Serwis',
    to: '/services',
    exact: true,
    icon: 'tools'
  },
  {
    name: 'Oferty',
    to: '/offers',
    exact: true,
    icon: 'copy'
  },
  {
    name: 'Rozliczenia',
    to: '/settlements',
    exact: true,
    icon: 'file-signature'
  }
];
