import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DELETE, GET } from '../../helpers/axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { WEBSOCKET_URL } from '../../helpers/endpoints';

const StyledNotificationWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 8px;
  max-height: 225px;
  overflow-y: auto;
  width: 285px;
`;
const StyledNotification = styled.div`
  width: 250px;
  margin-left: 8px;
  background-color: #0c85d0;
  padding: 8px;
  margin-top: 8px;
  display: flex;
  color: white;
  border-radius: 6px;
  font-size: 0.9rem;
`;
const StyledDescription = styled.div`
  width: 100%;
  margin-right: 8px;
`;
const StyledNotificationIcons = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
`;

const Notifications = ({ user }) => {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    GET('get-notifications')
      .then((res) => setNotifications(res.data))
      .catch(() => toast.error('Błąd pobierania powiadomień'));
  };

  const deleteNotifications = (id) => {
    DELETE(`/api/user_notifications/${id}`).then(() => getNotifications());
  };

  const showNotification = (id, link) => {
    deleteNotifications(id);
    history.push(link);
  };

  useEffect(() => {
    if (user.isAuth) {
      getNotifications();

      const connection = new WebSocket(WEBSOCKET_URL);

      // opening the connection
      connection.onopen = function() {
        console.log('Notifications on');
        // connection.send();
      };

      //info with connection error
      connection.onerror = function(error) {
        console.log('WebSocket Error ' + error);
      };

      //to receive the message from server
      connection.onmessage = function(e) {
        getNotifications();
      };

      connection.onclose = () => {
        console.log('Notifications closed');
      };
      return () => {
        connection.close();
      };
    }
  }, [user]);

  return (
    <StyledNotificationWrapper>
      {notifications.map(({ description, link, notification_id }) => (
        <StyledNotification key={`Notification${notification_id}`}>
          <StyledDescription>{description}</StyledDescription>
          <StyledNotificationIcons>
            <FontAwesomeIcon
              onClick={() => deleteNotifications(notification_id)}
              icon="times"
              transform="shrink-6 right-0.3 down-0.3"
              style={{ cursor: 'pointer' }}
            />
            {link && (
              <FontAwesomeIcon
                onClick={() => showNotification(notification_id, link)}
                icon="arrow-circle-right"
                transform="shrink-6 right-0.3 down-0.3"
                style={{ cursor: 'pointer' }}
              />
            )}
          </StyledNotificationIcons>
        </StyledNotification>
      ))}
    </StyledNotificationWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Notifications);
