// Dev
//export const SERVER = `https://tmb-dev.gorillasdev.pl`;

// Prod
export const SERVER = `https://tmb.gorillasdev.pl`;

// Local
// export const SERVER = `https://127.0.0.1:8000`;

export const JWT_LOGIN_URL = `${SERVER}/authentication_token`;
export const GRAPHQL_URL = `${SERVER}/api/graphql`;
export const API = `${SERVER}/api`;
export const uploads = `${SERVER}/api/file`;
export const PRIVATE_UPLOADS = `${SERVER}/api/file`;
export const publicUploads = `${SERVER}/uploads/public_files`;
export const WEBSOCKET_URL = 'wss://tmb.gorillasdev.pl:3004';